import React, { useEffect, useState, useRef } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import s from "./SwingTable.module.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { SelectV2 } from "../../../../components/redesign-components/SelectV2/SelectV2";
import { Tooltip } from "@material-ui/core";

const SortableHeader = ({
  header,
  handleSort,
  renderSortArrow,
  columnIndex,
  onHover,
}) => {
  const {
    setNodeRef,
    transform,
    transition,
    attributes,
    listeners,
    isDragging,
  } = useSortable({
    id: header.id,
    disabled: columnIndex === 0,
  });

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <th
      style={{
        ...style,
      }}
      ref={setNodeRef}
    >
      {header.isPlaceholder ? null : (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            style={{
              overflow: "hidden",
              wordBreak: "break-word",
              whiteSpace: "normal",
              maxWidth: "75px",
            }}
            onClick={() => handleSort(header.column.columnDef.accessorKey)}
            className={s.sortHeaderContainer}
            onMouseEnter={() => onHover(header.column.columnDef.accessorKey)}
            onMouseLeave={() => onHover(null)}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {renderSortArrow(header.column.columnDef.accessorKey)}
          </span>
          {columnIndex !== 0 && (
            <span {...attributes} {...listeners} style={{ cursor: "grab" }}>
              <DragIndicatorIcon />
            </span>
          )}
        </div>
      )}
    </th>
  );
};

export const SwingTable2 = ({
  data,
  analysisAggregates,
  currentPage,
  onPageChange,
  pageNumber,
  onSort,
  sort,
  columns,
  rowSelection,
  setRowSelection,
  selectedTableData,
  rowsPerPage,
  onRowsPerPageChange,
  filterValueIndicators,
}) => {
  const [columnOrder, setColumnOrder] = useState(columns.map((col) => col.id));
  const [potentialSortArrow, setPotentialSortArrow] = useState(null);

  const tableContainerRef = useRef(null);
  const isTableContainerDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleTableContainerMouseDown = (e) => {
    isTableContainerDragging.current = true;
    startX.current = e.pageX - tableContainerRef.current.offsetLeft;
    scrollLeft.current = tableContainerRef.current.scrollLeft;
  };

  const handleTableContainerMouseMove = (e) => {
    if (!isTableContainerDragging.current) return; // Only move if mouse is pressed
    e.preventDefault(); // Prevent default action (like text selection)
    const x = e.pageX - tableContainerRef.current.offsetLeft;
    const walk = (x - startX.current) * 1.5; // Adjust the scroll speed
    tableContainerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleTableContainerMouseUp = () => {
    isTableContainerDragging.current = false;
  };

  useEffect(() => {
    setColumnOrder(columns.map((col) => col.id));
  }, [columns]);

  const table = useReactTable({
    state: {
      rowSelection,
      columnOrder,
    },
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    columnResizeMode: "onChange",
  });

  const handleSort = (columnAccessorKey) => {
    let currentSortField = sort?.sort;
    const currentSortDirection = sort?.order;

    if (sort.sort === "videoCreated") {
      currentSortField = "videoPath";
    }

    const newDirection =
      currentSortField === columnAccessorKey
        ? currentSortDirection === "asc"
          ? "desc"
          : "asc"
        : "desc";
    onSort(columnAccessorKey, newDirection);
  };

  const renderPageNumber = () => {
    const arr = [];
    for (let i = 1; i <= pageNumber; i++) {
      arr.push(i);
    }
    if (pageNumber < 10) {
      return (
        <div style={{ display: "flex", gap: "10px" }}>
          {arr.map((el) => (
            <div
              key={el}
              onClick={() => onPageChange(el - 1)}
              className={` ${
                currentPage === el - 1
                  ? s.activePaginationButton
                  : s.paginationButton
              } `}
            >
              {el}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", gap: "10px" }}>
          {arr.slice(0, 3).map((el) => (
            <div
              key={el}
              onClick={() => onPageChange(el - 1)}
              className={` ${
                currentPage === el - 1
                  ? s.activePaginationButton
                  : s.paginationButton
              } `}
            >
              {el}
            </div>
          ))}
          <div
            style={{ cursor: "auto" }}
            key={0}
            className={s.paginationButton}
          >
            ...
          </div>
          {arr.slice(-3).map((el) => (
            <div
              key={el}
              onClick={() => onPageChange(el - 1)}
              className={` ${
                currentPage === el - 1
                  ? s.activePaginationButton
                  : s.paginationButton
              } `}
            >
              {el}
            </div>
          ))}
        </div>
      );
    }
  };

  const renderSortArrow = (accessorKey) => {
    let currentSortField = potentialSortArrow ? potentialSortArrow : sort?.sort;

    if (sort.sort === "videoCreated" || potentialSortArrow === "videoCreated") {
      currentSortField = "videoPath";
    }

    if (currentSortField === accessorKey) {
      if (potentialSortArrow) {
        if (sort.order === "desc") {
          return <ArrowDropUpIcon />;
        } else {
          return <ArrowDropDownIcon />;
        }
      } else {
        if (sort.order === "desc") {
          return <ArrowDropDownIcon />;
        } else {
          return <ArrowDropUpIcon />;
        }
      }
    } else {
      return "";
    }

    return currentSortField === accessorKey ? (
      sort.order === "desc" ? (
        <ArrowDropDownIcon />
      ) : (
        <ArrowDropUpIcon />
      )
    ) : (
      ""
    );
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (
      active.id !== over?.id &&
      active.id !== columnOrder[0] &&
      over?.id !== columnOrder[0]
    ) {
      setColumnOrder((prevOrder) => {
        const oldIndex = prevOrder.indexOf(active.id);
        const newIndex = prevOrder.indexOf(over.id);
        return arrayMove(prevOrder, oldIndex, newIndex);
      });
    }
  };

  const renderUnitType = (unit, value) => {
    const parsedUnit = unit ? unit?.toLowerCase() : "";
    const parsedValue = value ? +value : 0;

    switch (parsedUnit) {
      case "degrees":
        return `${parsedValue.toFixed()}°`;
      case "inches":
        return `${parsedValue.toFixed(1)}"`;
      case "mm":
        return `${parsedValue.toFixed()} mm`;
      case "seconds":
        return `${parsedValue.toFixed(2)} sec`;
      case "ratio":
        return `${parsedValue.toFixed(2)} ratio`;
      case "ms":
        return `${parsedValue.toFixed(1)} ms`;
      case "percent":
        return `${parsedValue.toFixed()}%`;
      case "mph":
        return `${parsedValue.toFixed(1)} mph`;
      case "m/s/s":
        return `${parsedValue.toFixed()} m/s^2`;
      case "g":
        return `${parsedValue.toFixed(1)} g`;
      case "degrees/second":
        return `${parsedValue.toFixed()} d/s`;
      case "order":
        return `${parsedValue.toFixed()}`;
      default:
        return parsedValue.toFixed();
    }
  };

  const renderAvgAndStd = (type) => {
    const orderedColumns = columnOrder
      .map((colId) => {
        const col = columns.find((col) => col.id === colId);
        const indicator = filterValueIndicators.find((col) => col.id === colId);
        if (indicator) {
          return { ...col, unit: indicator.unit };
        }
        return col;
      })
      .filter(Boolean);
    if (type === "avg") {
      return orderedColumns
        .slice(5)
        .map((el) => {
          const res = selectedTableData.reduce(
            (acc, el2) => {
              if (
                el2?.analysis?.indicators &&
                el.id in el2?.analysis?.indicators
              ) {
                acc.validSum += el2?.analysis?.indicators[el.id];
                acc.validLength += 1;
              }

              return acc;
            },
            { validLength: 0, validSum: 0 }
          );

          return {
            analysisData:
              el.id in analysisAggregates && analysisAggregates[el.id]?.avg
                ? analysisAggregates[el.id]?.avg
                : 0,
            selectedData: res.validLength
              ? (res.validSum / res.validLength).toFixed(2)
              : 0,
            unit: el.unit,
          };
        })
        .map((el) => {
          return (
            <td className={`${s.tableStdAndAvg} ${s.tableAvg}`}>
              <div className={s.stdAvgItemConatiner}>
                <div>
                  {renderUnitType(
                    el.unit,
                    el.analysisData ? el.analysisData : 0
                  )}
                </div>
                {selectedTableData.length ? (
                  <div className={s.stdAvgBorderedItem}>
                    {renderUnitType(
                      el.unit,
                      el.selectedData ? el.selectedData : 0
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </td>
          );
        });
    } else if (type === "std") {
      return orderedColumns
        .slice(5)
        .map((el) => {
          const { validSum, validLength } = selectedTableData.reduce(
            (acc, el2) => {
              if (
                el2?.analysis?.indicators &&
                el.id in el2?.analysis?.indicators
              ) {
                acc.validSum += el2?.analysis?.indicators[el.id];
                acc.validLength += 1;
              }
              return acc;
            },
            { validLength: 0, validSum: 0 }
          );
          const mean = validLength ? validSum / validLength : 0;
          const variance = selectedTableData.reduce((acc, el2) => {
            if (
              el2?.analysis?.indicators &&
              el.id in el2?.analysis?.indicators
            ) {
              const value = el2?.analysis?.indicators[el.id];
              acc += Math.pow(value - mean, 2); // (value - mean)^2
            }
            return acc;
          }, 0);

          const stdDev = validLength
            ? Math.sqrt(variance / validLength).toFixed(2)
            : 0;

          return {
            analysisData:
              el.id in analysisAggregates && analysisAggregates[el.id]?.std
                ? analysisAggregates[el.id]?.std
                : 0,
            selectedData: stdDev,
            unit: el.unit,
          };
        })
        .map((el) => {
          return (
            <td className={`${s.tableStdAndAvg} ${s.tableStd}`}>
              <div className={s.stdAvgItemConatiner}>
                <div>
                  {renderUnitType(
                    el.unit,
                    el.analysisData ? el.analysisData : 0
                  )}
                </div>
                {selectedTableData.length ? (
                  <div className={s.stdAvgBorderedItem}>
                    {renderUnitType(
                      el.unit,
                      el.selectedData ? el.selectedData : 0
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </td>
          );
        });
    }
  };

  const changePage = (dir) => {
    if (dir === "forward") {
      if (pageNumber > currentPage + 1) {
        onPageChange(currentPage + 1);
      }
    } else {
      if (currentPage + 1 > 1) {
        onPageChange(currentPage - 1);
      }
    }
  };

  return (
    <div>
      <div
        ref={tableContainerRef}
        onMouseDown={handleTableContainerMouseDown}
        onMouseMove={handleTableContainerMouseMove}
        onMouseLeave={handleTableContainerMouseUp} // In case the mouse leaves the table while dragging
        onMouseUp={handleTableContainerMouseUp}
        style={{ overflowX: "auto", width: "100%" }}
      >
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <table style={{ tableLayout: "fixed" }}>
            <thead>
              <SortableContext
                items={table
                  .getHeaderGroups()[0]
                  .headers.map((header) => header.id)}
                strategy={verticalListSortingStrategy}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                      <SortableHeader
                        key={header.id}
                        header={header}
                        handleSort={handleSort}
                        renderSortArrow={renderSortArrow}
                        columnIndex={index}
                        onHover={setPotentialSortArrow}
                      />
                    ))}
                  </tr>
                ))}
              </SortableContext>
            </thead>
            <tbody>
              <tr>
                <td className={`${s.tableStdAndAvg} ${s.tableAvg}`}></td>
                <td className={`${s.tableStdAndAvg} ${s.tableAvg}`} colSpan={4}>
                  <div className={s.stdAvgContainer}>
                    <div>Averages</div>

                    <div className={s.stdAvgSelected}>
                      {Object.entries(rowSelection).length
                        ? ` (${Object.entries(rowSelection).length}) Selected`
                        : ""}
                    </div>
                  </div>
                </td>
                {renderAvgAndStd("avg")}
              </tr>
              <tr>
                <td className={`${s.tableStdAndAvg} ${s.tableStd}`}></td>
                <td className={`${s.tableStdAndAvg} ${s.tableStd}`} colSpan={4}>
                  <div className={s.stdAvgContainer}>
                    <div>Std. Deviation</div>
                    <div className={s.stdAvgSelected}>
                      {Object.entries(rowSelection).length
                        ? ` (${Object.entries(rowSelection).length}) Selected`
                        : ""}
                    </div>
                  </div>
                </td>
                {renderAvgAndStd("std")}
              </tr>
            </tbody>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td>
                      {cell.column.id === cell.column.id.toUpperCase() &&
                      !cell.getContext().getValue() ? (
                        <Tooltip
                          title="This swing hasn’t been analyzed yet. Open this 
                          swing in the Sportsbox app to analyze its 3D data"
                        ><p>n/a</p></Tooltip>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </DndContext>
      </div>
      <div className={s.paginationContainer}>
        {pageNumber > 0 ? (
          <div
            onClick={() => changePage("back")}
            className={s.paginationButton}
          >
            <ArrowBackIcon fontSize="small" /> Previous
          </div>
        ) : (
          <div></div>
        )}

        <SelectV2
          label="Items per page"
          name="rowsPerPage"
          onChange={(_, value) => onRowsPerPageChange(value)}
          options={[
            { text: 10, value: 10 },
            { text: 20, value: 20 },
            { text: 50, value: 50 },
            { text: 100, value: 100 },
            { text: 250, value: 250 },
          ]}
          value={rowsPerPage}
          width="100px"
        />

        {renderPageNumber()}

        <div style={{ width: "100px" }}> </div>

        {pageNumber > 1 ? (
          <div
            onClick={() => changePage("forward")}
            className={s.paginationButton}
          >
            Next <ArrowForwardIcon fontSize="small" />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
