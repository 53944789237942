import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Swal from "sweetalert2";
import moment from "moment";
import "./offerPaid.css";
import { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import { StripeSubscription } from "../../components/stripeSubscription";
import { listOfSubscriptions } from "../../common/envConfig";

function PaymentComponent({ uid, email, handleProduct, finishPayment }) {
  const [productId, setProductId] = useState(
    listOfSubscriptions.STUDENT_LITE_ANNUALLY
  );

  useEffect(() => {
    handleProduct(productId);
  }, [productId]);

  const createSubscription = async (data) => {
    let referralCode = window.location.href;
    if (referralCode.includes("REFERRALCODE=")) {
      referralCode = referralCode.split("REFERRALCODE=")[1];
    } else {
      referralCode = "";
    }
    try {
      await axiosWithToken(functionBaseUrl + "/api/subscription", {
        method: "POST",
        data: {
          userId: uid,
          plan: productId,
          planDescription: "",
          planType:
            productId === listOfSubscriptions.STUDENT_LITE_MONTHLY
              ? "Monthly"
              : "Annually",
          transactionId: data.id,
          payload: "",
          packageId: "",
          orderId: "",
          amount: (data.amount / 100).toFixed(2),
          startDate: String(Date.now()),
          endDate:
            productId == listOfSubscriptions.STUDENT_LITE_MONTHLY
              ? String(moment().add(1, "month").valueOf())
              : String(moment().add(1, "year").valueOf()),
          lastUpdated: String(Date.now()),
          autoRenewal: true,
          platform: "Stripe",
          productId: productId,
          referralCode: referralCode ? referralCode : "",
          promoCode: "aNRQuT1C",
        },
      })
        .then(async (result) => {
          await axiosWithToken(functionBaseUrl + "/api/updateS/" + result.data.id, {
            method: "POST",
            data: {
              subLabel: "markCrossfield",
            },
          }).catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
        });

      await axiosWithToken(functionBaseUrl + "/api/userCoach", {
        method: "POST",
        data: {
          userEmail: email?.toLowerCase()?.replace(/ /g, ""),
          coachEmail: "3dcoach.contest@gmail.com",
        },
      }).catch((err) => {
        console.log(err);
      });

      await axiosWithToken(functionBaseUrl + "/api/users/" + uid, {
        method: "PATCH",
        data: {
          markCrossfield: {
            newUser: true,
            date: String(Date.now()),
          }
        }
      }).catch((err) => {
        console.log(err);
      });

      finishPayment();
    } catch (e) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
        icon: "error",
      });
    }
  };

  return (
    <>
      <div
        className={
          productId == listOfSubscriptions.STUDENT_LITE_ANNUALLY
            ? "offer-product special active"
            : "offer-product special"
        }
        onClick={() => setProductId(listOfSubscriptions.STUDENT_LITE_ANNUALLY)}
      >
        <div className="offer-product-cta">Best value</div>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <div className="offer-product-name">
              Annual - billed at <s>$110/year</s> $99/year
            </div>
            <div className="offer-product-price">$8.25/month</div>
            {/*<div className="offer-product-billed">
              Billed annually after 30 days
            </div>*/}
          </Grid>
          <Grid item xs={1} className="offer-product-check-wrapper">
            <CheckIcon className="offer-product-check" />
          </Grid>
        </Grid>
      </div>
      <div
        className={
          productId == listOfSubscriptions.STUDENT_LITE_MONTHLY
            ? "offer-product active"
            : "offer-product"
        }
        onClick={() => setProductId(listOfSubscriptions.STUDENT_LITE_MONTHLY)}
      >
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <div className="offer-product-name">Monthly</div>
            <div className="offer-product-price"><s>$15.99/month</s> $14.39/month</div>
            {/*<div className="offer-product-billed">
              Billed monthly after 30 days
            </div>*/}
          </Grid>
          <Grid item xs={1} className="offer-product-check-wrapper">
            <CheckIcon className="offer-product-check" />
          </Grid>
        </Grid>
      </div>
      <div className="offer-payment">
        <StripeSubscription
          email={email}
          productId={productId}
          handleResult={createSubscription}
          //freeTrial={30}
          hideCoupon={true}
          showDetails={false}
          //buttonText="Start 30-day free trial"
          fullName={""}
        />
        <div className="offer-payment-disclaimer">
          You will be charged $
          {productId == listOfSubscriptions.STUDENT_LITE_ANNUALLY
            ? "99.00"
            : "14.39"}{" "}
          and will auto-renew unless canceled. Manage or cancel anytime in your account settings.
        </div>
      </div>
    </>
  );
}

export const Payment = PaymentComponent;
