import React, { useEffect, useState } from "react";
import s from "./styles.module.css";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { HorizontalScrollWrapper } from "../HorizontalScrollWrapper/HorizontalScrollWrapper";
import { axiosWithToken, functionBaseUrl } from "../../../../common/firebase";

const testData = [
  {
    category: "Pelvis Turn",
    items: [
      { value: "PLVTRNADR", text: "Pelvis Turn at Address" },
      { value: "PLVTRNTOP", text: "Pelvis Turn at Top" },
      { value: "PLVTRNIMP", text: "Pelvis Turn at Impact" },
      { value: "PLVTRNBCKSMX", text: "Pelvis Turn at Backswing Max" },
    ],
  },
  {
    category: "Chest Turn",
    items: [
      { value: "CHSTTRNADR", text: "Chest Turn at Address" },
      { value: "CHSTTRNTOP", text: "Chest Turn at Top" },
      { value: "CHSTTRNIMP", text: "Chest Turn at Impact" },
      { value: "CHSTTRNBCKSMX", text: "Chest Turn at Backswing Max" },
      { value: "XFCTRSTRCH", text: "X-Factor Stretch" },
      { value: "XFCTRIMP", text: "X-Factor at Impact" },
    ],
  },
  {
    category: "X-Factor",
    items: [
      { value: "XFCTRBNKSMX", text: "X-Factor at Backswing Max" },
      { value: "XFCTRSTRCH", text: "X-Factor Stretch" },
      { value: "XFCTRIMP", text: "X-Factor at Impact" },
    ],
  },
  {
    category: "Sway",
    items: [
      { value: "PLVSWYTOP", text: "Pelvis Sway at Top" },
      { value: "PLVSWYBCKSMX", text: "Pelvis Sway at Backswing Max" },
      { value: "PLVSWYIMP", text: "Pelvis Sway at Impact" },
      { value: "CHSTSWYTOP", text: "Chest Sway at Top" },
      { value: "CHSTSWYBCKSMX", text: "Chest Sway at Backswing Max" },
      { value: "CHSTSWYIMP", text: "Chest Sway at Impact" },
      { value: "CHSTSLDTRANS", text: "Chest Slide at Transition" },
      { value: "SWYGAPADR", text: "Sway Gap at Address" },
      { value: "SWYGAPIMP", text: "Sway Gap at Impact" },
      { value: "SWYGAPTOP", text: "Sway Gap at Top" },
    ],
  },
  {
    category: "Side Bend",
    items: [
      { value: "PLVSBNDADR", text: "Pelvis Side Bend at Address" },
      { value: "PLVSBNDTOP", text: "Pelvis Side Bend at Top" },
      { value: "PLVSBNDIMP", text: "Pelvis Side Bend at Impact" },
    ],
  },
  {
    category: "Lift",
    items: [
      { value: "PLVSBNDADR", text: "Pelvis Side Bend at Address" },
      { value: "PLVSBNDTOP", text: "Pelvis Side Bend at Top" },
      { value: "PLVSBNDIMP", text: "Pelvis Side Bend at Impact" },
    ],
  },
  {
    category: "Flex",
    items: [
      { value: "PLVSBNDADR", text: "Pelvis Side Bend at Address" },
      { value: "PLVSBNDTOP", text: "Pelvis Side Bend at Top" },
      { value: "PLVSBNDIMP", text: "Pelvis Side Bend at Impact" },
    ],
  },
];

export const IndicatorSelectList = ({ isOpen, values, onSave, onClose }) => {
  const [options, setOptions] = useState([]);
  const [tagFilter, setTagFilter] = useState("All");
  const [selectedValues, setSelectedValues] = useState([]);
  /*
  { value: "CHSTTRNTOP", text: "Chest Turn at Top" },
  { value: "CHSTSBNDFTHRUMAX", text: "Chest Side Bend FollowThru Max" },
  { value: "XFCTRMAXNRTOP", text: "X-Factor Max Near TOP" },
  */

  useEffect(() => {
    (async () => {
      const response = await axiosWithToken(
        `${functionBaseUrl}/api/utility/action-sdk-indicators`,
        {
          method: "GET",
        }
      );

      setOptions(response.data);
    })();
  }, []);

  useEffect(() => {
    setSelectedValues(values.map((el) => el.id));
  }, [values]);

  const handleSelectValue = (value) => {
    setSelectedValues((prev) => {
      return prev.includes(value)
        ? prev.filter((el) => el !== value)
        : [...prev, value];
    });
  };

  const handleMultipleSelectValues = (values) => {
    setSelectedValues((prev) => {
      return [...prev, ...values.filter((el) => !prev.includes(el))];
    });
  };

  const handleApply = () => {
    const data = options.reduce((acc, el) => {
      return [...acc, ...el.items];
    }, []);

    const filtered = data.filter((el) => {
      return selectedValues.includes(el.id);
    });
    onSave(filtered);
    onClose();
  };

  const handleSelectAll = () => {
    handleMultipleSelectValues(
      options
        .reduce((acc, el) => {
          return [...acc, ...el.items];
        }, [])
        .map((it) => it.id)
    );
  };

  return (
    <div style={{ display: isOpen ? "block" : "none" }} className={s.container}>
      <div className={s.header}>
        <div>Add Data</div>
        <CloseOutlinedIcon style={{ cursor: "pointer" }} onClick={onClose} />
      </div>

      <div>Apply or remove data to the table</div>

      <div style={{ margin: "20px 0" }}>
        <HorizontalScrollWrapper>
          {/* <div className={s.tagList}> */}
          {[{ category: "All" }, ...options].map((el) => {
            return (
              <div
                onClick={() => setTagFilter(el.category)}
                className={`${s.tag} ${
                  tagFilter === el.category ? s.activeTag : ""
                }`}
              >
                {el.category}
              </div>
            );
          })}
          {/* </div> */}
        </HorizontalScrollWrapper>
      </div>

      <div className={s.categories}>
        {options
          .filter((el) => {
            if (tagFilter === "All") {
              return true;
            } else {
              return el.category === tagFilter;
            }
          })
          .map((el) => {
            return (
              <div>
                <div className={s.category}>
                  <div>{el.category}</div>
                  <div
                    onClick={() =>
                      handleMultipleSelectValues(el.items.map((it) => it.id))
                    }
                    className={s.purpleTextButton}
                  >
                    + Add all
                  </div>
                </div>

                <div className={s.categoryItemsContainer}>
                  {el.items.map((el2) => {
                    return (
                      <div
                        className={s.categoryItem}
                        onClick={() => handleSelectValue(el2.id)}
                      >
                        {selectedValues.includes(el2.id) ? (
                          <CheckBoxIcon style={{ color: "#9e6cfc" }} />
                        ) : (
                          <CheckBoxOutlineBlankIcon
                            style={{ color: "#D0D5DD" }}
                          />
                        )}

                        <span>{el2.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>

      <div className={s.buttonsContainer}>
        <div
          style={
            options.reduce((acc, el) => {
              return [...acc, ...el.items];
            }, []).length !== selectedValues.length
              ? {}
              : { color: "#CDCFD1" }
          }
          onClick={() => handleSelectAll()}
          className={s.purpleTextButton}
        >
          include all
        </div>
        <div className={s.buttonsGroup}>
          <button onClick={onClose} className={s.cancelButton}>
            cancel
          </button>

          <button
            disabled={selectedValues.length ? false : true}
            onClick={handleApply}
            className={s.applyButton}
          >
            apply
          </button>
        </div>
      </div>
    </div>
  );
};
